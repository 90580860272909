/**
 * API actions
 * ref: https://github.com/redux-utilities/flux-standard-action
 */
import {
  axios,
  getAuthUrl,
  startRequest,
  completeRequest,
  NOK_RESPONSE_HINT,
  getApiUrl,
} from './api-base.js';
import { HOME_VIEW, LOGIN_VIEW, MENU_VIEW, navigate } from '../navigation.js';
import { requestSubscriptionAndPortal } from './subscription-api.js';
import { setToken } from '../token.js';
import { ID_PROVIDER } from '../../utilities/constants.js';
import { getProfile } from './profile-api';

const TOKEN_REQUEST = 'TOKEN_REQUEST';
const REGISTER_REQUEST = 'REGISTER_REQUEST';
const LOGIN_REQUEST = 'LOGIN_REQUEST';
const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';

// REST resources
const TOKEN_RESOURCE = 'token/refresh';
const REGISTER_RESOURCE = 'register';
const LOGIN_RESOURCE = 'login';
const RESET_PASSWORD_RESOURCE = 'reset-password';
const UPDATE_PASSWORD_RESOURCE = 'update-password';

const GRANT_TYPE = 'client_credentials';

/**
 * returns action to monitor with requestMiddleware: { payload: async function }
 */
/* eslint no-undef: "off" */
export function requestToken(refreshToken, portalHandler) {
  return {
    type: TOKEN_REQUEST,
    payload(dispatch) {
      dispatch(startRequest());

      const tokenUrl = getApiUrl(TOKEN_RESOURCE);

      return axios
        .post(tokenUrl, {
          refresh_token: refreshToken,
        })
        .then(response => {
          dispatch([setToken(response.data.token, response.data.refresh_token), completeRequest()]);

          if (ID_PROVIDER) {
            // subscription thunk
            return dispatch(requestSubscriptionAndPortal(portalHandler));
          }

          dispatch(navigate(MENU_VIEW));
          return false;
        })
        .catch(error => {
          dispatch(completeRequest(NOK_RESPONSE_HINT));
          dispatch(navigate(LOGIN_VIEW));
          console.log(error);
        });
    },
  };
}

/**
 * returns action to monitor with requestMiddleware: { payload: async function }
 */
/* eslint no-undef: "off" */
export function register(first, last, email, password, familySize, options, callback) {
  return {
    type: REGISTER_REQUEST,
    payload(dispatch) {
      dispatch(startRequest());

      const tokenUrl = getAuthUrl(REGISTER_RESOURCE);

      return axios
        .post(tokenUrl, {
          first,
          last,
          email,
          password,
          familySize,
          options,
        })
        .then(response => {
          dispatch([setToken(response.data.token, response.data.refresh_token), completeRequest()]);
          dispatch(getProfile());
          //   if (ID_PROVIDER) {
          //     // subscription thunk
          //     return dispatch(requestSubscriptionAndPortal(portalHandler));
          //   }
          callback(response.data.id, response.data.sponsored ?? false);
          return false;
        })
        .catch(error => {
          dispatch(completeRequest(error.response?.data?.message ?? NOK_RESPONSE_HINT));
          console.log(error);
        });
    },
  };
}

/**
 * returns action to monitor with requestMiddleware: { payload: async function }
 */
/* eslint no-unused-vars: "off" */
export function login(email, password, portalHandler) {
  return {
    type: LOGIN_REQUEST,
    payload(dispatch) {
      dispatch(startRequest());

      const loginUrl = getAuthUrl(LOGIN_RESOURCE);

      return axios
        .post(loginUrl, {
          username: email,
          password,
        })
        .then(response => {
          dispatch(setToken(response.data.token, response.data.refresh_token));
          dispatch(completeRequest());
          dispatch(getProfile());
          portalHandler();
          dispatch(navigate(HOME_VIEW));
        })
        .catch(error => {
          dispatch(completeRequest('Oops, wrong email or password!'));
        });
    },
  };
}

/**
 * returns action to monitor with requestMiddleware: { payload: async function }
 */
/* eslint no-unused-vars: "off" */
export function forgotPassword(email, gotToLogin = false) {
  return {
    type: FORGOT_PASSWORD_REQUEST,
    payload(dispatch) {
      dispatch(startRequest());

      const url = getAuthUrl(RESET_PASSWORD_RESOURCE);

      return axios
        .post(url, {
          email,
        })
        .then(response => {
          const actions = [completeRequest(response.data.message)];
          if (gotToLogin) {
            actions.push(navigate(LOGIN_VIEW));
          }
          dispatch(actions);
        })
        .catch(error => {
          dispatch(completeRequest('Oops, wrong email or password!'));
        });
    },
  };
}

/**
 * returns action to monitor with requestMiddleware: { payload: async function }
 */
/* eslint no-unused-vars: "off" */
export function resetForgotPassword(token, password, nextPage = LOGIN_VIEW) {
  return {
    type: FORGOT_PASSWORD_REQUEST,
    payload(dispatch) {
      dispatch(startRequest());

      const url = getAuthUrl(UPDATE_PASSWORD_RESOURCE);

      return axios
        .post(url, {
          token,
          password,
        })
        .then(response => {
          dispatch([completeRequest(response.data.message), navigate(nextPage)]);
        })
        .catch(error => {
          if (error.response.status === 401) {
            const ddApp = document.querySelector('dd-app');
            ddApp.showResetLinkExpired_();
          } else {
            dispatch(completeRequest('Oops, wrong invalid password reset token!'));
          }
        });
    },
  };
}
